import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {
  IoScaleOutline,
  IoInformationCircleOutline,
  IoRibbonOutline,
} from "react-icons/io5";

function ProductComparisonPageTemplate({ currentPage }) {
  return (
    <Layout>
      <Seo
        title={currentPage.seo.title}
        description={currentPage.seo.metaDesc}
      />
      <div className="container">
        <div className="my-8 lg:my-20 max-w-lg">
          <h1
            className="lg:text-4xl mb-3"
            dangerouslySetInnerHTML={{
              __html: currentPage.pages.h1Title
                ? currentPage.pages.h1Title
                : currentPage.title,
            }}
          />
          <div
            className="lg:text-lg leading-open"
            dangerouslySetInnerHTML={{ __html: currentPage.content }}
          />
        </div>
        <div className="grid lg:grid-cols-2 xl:grid-cols-4 lg:gap-x-4 gap-y-6 mb-12 mx-auto px-4 xl:px-0 max-w-5xl">
          {currentPage.layout.sections.map((section, index) => {
            return (
              <div
                className="flex flex-col justify-between rounded-lg overflow-hidden text-grey-darkest bg-grey-lighter"
                key={index}
              >
                <div>
                  <Link to={`/${section.sectionTitleLink}`}>
                    <img
                      src={section.photo && section.photo.localFile.publicURL}
                      className="h-[200px] w-full object-cover"
                      alt={section.photo && section.photo.alt}
                      title={section.photo && section.photo.title}
                    />
                  </Link>
                  <div className="p-4">
                    {section.emphasizedProduct && (
                      <p className="orange-tag text-sm">Most Popular</p>
                    )}
                    <h3 className="text-lg font-bold">
                      <Link
                        to={section.sectionTitleLink}
                        className="no-underline text-black"
                      >
                        {section.sectionTitle}
                      </Link>
                    </h3>

                    <div
                      className="text-grey-darker leading-loose hide-ul"
                      dangerouslySetInnerHTML={{
                        __html: section.sectionBody,
                      }}
                    />
                  </div>
                </div>
                <div className="p-4">
                  <ul className="mb-6 list-none pl-0 text-grey-darker w-full">
                    <li className="flex items-center mb-2">
                      <IoRibbonOutline
                        className="mr-2 text-black flex-shrink-0"
                        size={25}
                      />
                      {section.featureItems.warranty}
                    </li>
                    <li className="flex items-center mb-2">
                      <IoScaleOutline
                        className="mr-2 text-black flex-shrink-0"
                        size={20}
                      />
                      {section.featureItems.weightSupport}
                    </li>
                    <li className="flex items-center mb-2">
                      <IoInformationCircleOutline
                        className="mr-2 text-black flex-shrink-0"
                        size={22}
                      />
                      {section.featureItems.extraInfo}
                    </li>
                  </ul>
                  <div className="border-t border-grey"></div>
                  <div className="flex justify-between items-center py-3">
                    <div className="text-sm">Starting at</div>
                    <div
                      className="text-2xl font-bold"
                      dangerouslySetInnerHTML={{ __html: section.price }}
                    />
                  </div>

                  <Link
                    to={section.buttonLink}
                    className={`block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm px-4 py-2 no-underline rounded text-center`}
                  >
                    {section.buttonText}
                  </Link>
                  <p className="mt-2 text-grey-dark text-sm leading-tight">
                    <small>{section.postButtonText}</small>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
export default ProductComparisonPageTemplate;
